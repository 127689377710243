<template>
  <div>
    <div v-if="!pageIndexApiDataIsLoading">
      <company-licence-component/>

      <div class="row">
        <div class="col-md-12 m-b-0">
          <router-link class="btn btn-white m-r-5" to="/monitoring"><i class="fa fa-tv fa-fw fa-lg"></i></router-link>
          <router-link class="btn btn-white m-r-5" to="/monitoring/prospection"><i class="fa fa-map fa-fw fa-lg"></i>
            Prospection
          </router-link>
          <router-link class="btn btn-white m-r-5 text-orange" to="/monitoring/sales"><i
              class="fa fa-chart-bar fa-chart-bar fa-lg text-orange"></i> Vente &amp; Facturation
          </router-link>
          <router-link class="btn btn-white m-r-5" to="/monitoring/vaults"><i class="fa fa-dollar-sign fa-fw fa-lg"></i>
            Caisse &amp; Dépenses
          </router-link>
          <router-link class="btn btn-white m-r-5" to="/monitoring/scm"><i
              class="fa fa-shopping-basket fa-fw fa-lg"></i> Appro. &amp; Achats
          </router-link>
          <router-link class="btn btn-white m-r-5" to="/monitoring/production"><i
              class="fa fa fa-flask fa-fw fa-lg"></i> Usine &amp; Production
          </router-link>
          <router-link class="btn btn-white m-r-5" to="/monitoring/stock"><i class="fa fa-cube fa-fw fa-lg"></i> Stock
            &amp; Inventaires
          </router-link>
          <router-link class="btn btn-white m-r-5" to="/monitoring/admin"><i class="fa fa-cogs fa-fw fa-lg"></i> Console
            Admin
          </router-link>
        </div>
      </div>


      <!--<h1 class="page-header">Centre de controle  <small class="lead">.</small></h1>-->
      <hr class="bg-grey-lighter"/>

      <div class="row m-b-5">
        <div class="col-md-12 m-b-0">
          <router-link class="btn btn-white m-r-5" to="/monitoring/sales"><i class="fa fa-chart-bar fa-fw fa-lg"></i></router-link>
          <router-link class="btn btn-white m-r-5 text-orange" to="/monitoring/sales/sales-periodic-report-per-warehouse"><i class="fa fa-calendar fa-fw fa-lg text-orange"></i> Suivi Vente &amp; Encaissement</router-link>
          <router-link class="btn btn-white m-r-5" to="/monitoring/sales/clients-account-summary"><i class="fa fa-users fa-fw fa-lg"></i> Relevé Comptes Clients</router-link>
          <router-link class="btn btn-white m-r-5" to="/monitoring/sales/invoice-ageing"><i class="fa fa-clock fa-fw fa-lg"></i> Echéances Factures</router-link>
          <router-link class="btn btn-white m-r-5" to="/monitoring/sales/sleeping-clients-on-list"><i class="fa fa-bed fa-fw fa-lg"></i> Clients Dormants</router-link>
          <router-link class="btn btn-white m-r-5" to="/monitoring/sales/sales-orders"><i class="fa fa-file-alt fa-fw fa-lg"></i> Factures Spéciales</router-link>
          <router-link class="btn btn-white m-r-5" to="/monitoring/sales/order-payments"><i class="fa fa-dollar-sign fa-fw fa-lg"></i> Règlements Spéciaux</router-link>
        </div>
      </div>

      <div class="row m-b-5">
        <div class="col-md-12 m-b-0">
          <router-link class="btn btn-white m-r-5 text-orange"
                       to="/monitoring/sales/sales-periodic-report-per-warehouse"><i
              class="fa fa-home fa-fw fa-lg text-orange"></i> Par Point de Vente
          </router-link>
        </div>
      </div>


      <div v-if="pageIndexApiData != null">
        <div class="row text-right m-b-5">
          <div class="col-12">
            <a href="#" class="btn btn-dark m-l-5" @click="$bvModal.show('formModal')"><i class="fa fa-filter fa-fw fa-lg"></i></a>
          </div>
        </div>
        <div class="row">
          <div class="col-12" v-if="queryApiData != null">
            <div class="row">
              <div class="col-12" >
                <div class="invoice">
                  <!-- begin invoice-company -->
                  <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                                <a :href="queryApiData.document_url_pdf"
                                                   target="_blank" class="btn btn-sm btn-white m-b-10 mr-2"
                                                   v-if="queryApiData.document_url_pdf != null"><i
                                                    class="fa fa-file-pdf text-danger fa-fw fa-lg"></i> PDF</a>
                                            </span>
                    <h1>{{ queryApiData.warehouse_name }}<br/>
                      <small class="lead">Suivi Vente &amp; Encaissement du {{ queryApiData.date_from }} au
                        {{ queryApiData.date_to }}</small>
                    </h1>
                  </div>
                  <!-- end invoice-company -->

                  <!-- begin invoice-content -->
                  <div class="invoice-content">
                    <div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="widget widget-stats bg-gradient-teal">
                            <div class="stats-icon stats-icon-lg"><i class="fa fa-clock fa-fw"></i></div>
                            <div class="stats-info">
                              <h3 class="f-s-17 f-w-300 text-white"><b>CHIFFRE D'AFFAIRES</b></h3>
                              <p class="text-right">
                                <small>
                                  {{ queryApiData.credit_sales_today|formatMoney }}
                                  <small>
                                    <small> A TERME</small>
                                  </small>
                                </small>
                              </p>
                              <p class="text-right">
                                <small>
                                  {{ queryApiData.cash_sales_today|formatMoney }}
                                  <small>
                                    <small> EN ESPECE</small>
                                  </small>
                                </small>
                              </p>
                              <br/>
                              <div class="stats-progress progress">
                                <div class="progress-bar" style="width: 100%;"></div>
                              </div>
                              <p class="text-right">
                                {{ queryApiData.total_sales_today|formatMoney }}
                                <small> {{ appCurrency }}</small>
                              </p>
                            </div>
                            <div class="stats-link">
                              <a href="javascript:;"><b>CHIFFRE D'AFFAIRES</b>
                                <!--<i class="fa fa-chart-line"></i>--></a>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="widget widget-stats bg-gradient-orange">
                            <div class="stats-icon stats-icon-lg"><i class="fa fa-dollar-sign fa-fw"></i></div>
                            <div class="stats-info">
                              <h3 class="f-s-17 f-w-300 text-white"><b>RECETTE</b></h3>
                              <p class="text-right">
                                <small>
                                  {{ queryApiData.order_cash_payments_today|formatMoney }}
                                  <small>
                                    <small> VENTES</small>
                                  </small>
                                </small>
                              </p>
                              <p class="text-right">
                                <small>
                                  {{ queryApiData.order_cash_outs_today|formatMoney }}
                                  <small>
                                    <small> RECOUV.</small>
                                  </small>
                                </small>
                              </p>
                              <br/>
                              <div class="stats-progress progress">
                                <div class="progress-bar" style="width: 100%;"></div>
                              </div>
                              <p class="text-right">
                                {{ queryApiData.total_payments_today|formatMoney }}
                                <small> {{ appCurrency }}</small>
                              </p>
                            </div>
                            <div class="stats-link">
                              <a href="javascript:;"><b>RECETTE</b>
                                <!--<i class="fa fa-chart-line"></i>--></a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div id="my-accordion" class="accordion">
                        <b-card no-body>
                          <b-card-header header-tag="header" class="card-header text-black pointer-cursor"
                                         v-b-toggle.accordion1>
                            <h2><i class="fa fa-arrow-circle-down text-orange"></i> Détails Ventes</h2>
                          </b-card-header>
                          <b-collapse id="accordion1" accordion="my-accordion" visible>
                            <b-card-body>
                              <h3 class="m-t-10"><i class="fa fa-arrow-circle-right text-orange"></i> Ventes marchandises
                              </h3>
                              <b-tabs nav-class="nav-tabs-inverse" nav-wrapper-class="nav nav-tabs">
                                <b-tab active>
                                  <template slot="title">
                                    <span class="d-sm-none">Agents</span>
                                    <span class="d-sm-block d-none">Ventes par agent <span
                                        class="badge badge-warning">{{ queryApiData.table_view_employees_sales_count }}</span></span>
                                  </template>
                                  <div class="table-responsive">
                                    <table class="table table-striped m-b-0">
                                      <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>LOCALITE</th>
                                        <th>DESIGNATION</th>
                                        <th>TELEPHONE</th>
                                        <th>ESPECE</th>
                                        <th>A TERME</th>
                                        <th>CHIF D'AFF</th>
                                        <th>RECOUVR.</th>
                                        <th>RECETTE</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr v-for="(item, index) in queryApiData.table_view_employees_sales"
                                          :key="index">
                                        <td><b>{{ index + 1 }}</b></td>
                                        <td>{{ item.location_name }}</td>
                                        <td><b>{{ item.name }}</b></td>
                                        <td>{{ item.phone }}</td>
                                        <td><b>{{ item.ventes_espece|formatMoney }}</b></td>
                                        <td><b>{{ item.ventes_a_terme|formatMoney }}</b></td>
                                        <td><b>{{ item.total_ventes|formatMoney }}</b></td>
                                        <td><b>{{ item.recouvrement|formatMoney }}</b></td>
                                        <td><b>{{ item.recette|formatMoney }}</b></td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </b-tab>
                                <b-tab>
                                  <template slot="title">
                                    <span class="d-sm-none">Articles</span>
                                    <span class="d-sm-block d-none">Ventes par article <span
                                        class="badge badge-warning">{{ queryApiData.table_view_products_sales_count }}</span></span>
                                  </template>
                                  <div class="table-responsive">
                                    <table class="table table-striped m-b-0">
                                      <thead>
                                      <tr class="bg-white">
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th colspan="2" class="bg-teal text-white text-center">VENTES ESPECE</th>
                                        <th colspan="2" class="bg-orange text-white text-center">VENTES A TERME</th>
                                        <th colspan="2" class="bg-teal text-white text-center">TOTAL VENTES</th>
                                      </tr>
                                      <tr>
                                        <th>#</th>
                                        <th>REF</th>
                                        <th>DESIGNATION</th>
                                        <th class="text-center">QUANTITE</th>
                                        <th class="text-center">MONTANT</th>
                                        <th class="text-center">QUANTITE</th>
                                        <th class="text-center">MONTANT</th>
                                        <th class="text-center">QUANTITE</th>
                                        <th class="text-center">MONTANT</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr v-for="(item, index) in queryApiData.table_view_products_sales"
                                          :key="index">
                                        <td><b>{{ index + 1 }}</b></td>
                                        <td><b>{{ item.erp_code }}</b></td>
                                        <td><b>{{ item.name }}</b></td>
                                        <td class="text-center"><b>{{ item.quantite_espece|formatDecimalNumber }}</b></td>
                                        <td class="text-center"><b>{{ item.ventes_espece|formatMoney }}</b></td>
                                        <td class="text-center"><b>{{ item.quantite_a_terme|formatDecimalNumber }}</b></td>
                                        <td class="text-center"><b>{{ item.ventes_a_terme|formatMoney }}</b></td>
                                        <td class="text-center"><b>{{ item.total_quantite|formatDecimalNumber }}</b></td>
                                        <td class="text-center"><b>{{ item.total_ventes|formatMoney }}</b></td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </b-tab>
                                <b-tab>
                                  <template slot="title">
                                    <span class="d-sm-none">Clients</span>
                                    <span class="d-sm-block d-none">Ventes par client <span
                                        class="badge badge-warning">{{ queryApiData.table_view_client_sales_count }}</span></span>
                                  </template>
                                  <div class="table-responsive">
                                    <table class="table table-striped m-b-0">
                                      <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>REFERENCE</th>
                                        <th>TYPE</th>
                                        <th>LOCALITE</th>
                                        <th>DESIGNATION</th>
                                        <th>TELEPHONE</th>
                                        <th>ESPECE</th>
                                        <th>A TERME</th>
                                        <th>CHIF D'AFF</th>
                                        <th>RECOUVR.</th>
                                        <th>RECETTE</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr v-for="(item, index) in queryApiData.table_view_client_sales"
                                          :key="index">
                                        <td><b>{{ index + 1 }}</b></td>
                                        <td><b><a href="javascript:;"
                                                  @click="showClientItem(item.id)">{{ item.erp_code != null && item.erp_code != '' ? item.erp_code : '-----------------' }}</a></b>
                                        </td>
                                        <td>{{ item.group_name }}</td>
                                        <td>{{ item.location_name }}</td>
                                        <td><b>{{ item.name }}</b></td>
                                        <td>{{ item.phone }}</td>
                                        <td><b>{{ item.ventes_espece|formatMoney }}</b></td>
                                        <td><b>{{ item.ventes_a_terme|formatMoney }}</b></td>
                                        <td><b>{{ item.total_ventes|formatMoney }}</b></td>
                                        <td><b>{{ item.recouvrement|formatMoney }}</b></td>
                                        <td><b>{{ item.recette|formatMoney }}</b></td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </b-tab>
                                <b-tab>
                                  <template slot="title">
                                    <span class="d-sm-none">Clients Codes</span>
                                    <span class="d-sm-block d-none">Ventes par code client <span
                                        class="badge badge-warning">{{ queryApiData.table_view_client_code_sales_count }}</span></span>
                                  </template>
                                  <div class="table-responsive">
                                    <table class="table table-striped m-b-0">
                                      <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>CODE CLIENT</th>
                                        <th>DESIGNATION</th>
                                        <th>ESPECE</th>
                                        <th>A TERME</th>
                                        <th>CHIF D'AFF</th>
                                        <th>RECOUVR.</th>
                                        <th>RECETTE</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr v-for="(item, index) in queryApiData.table_view_client_code_sales"
                                          :key="index">
                                        <td><b>{{ index + 1 }}</b></td>
                                        <td><b>{{ item.erp_code }}</b></td>
                                        <td><b>{{ item.name }}</b></td>
                                        <td><b>{{ item.ventes_espece|formatMoney }}</b></td>
                                        <td><b>{{ item.ventes_a_terme|formatMoney }}</b></td>
                                        <td><b>{{ item.total_ventes|formatMoney }}</b></td>
                                        <td><b>{{ item.recouvrement|formatMoney }}</b></td>
                                        <td><b>{{ item.recette|formatMoney }}</b></td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </b-tab>
                                <b-tab>
                                  <template slot="title">
                                    <span class="d-sm-none">Type Clients</span>
                                    <span class="d-sm-block d-none">Ventes par type client <span
                                        class="badge badge-warning">{{ queryApiData.table_view_client_group_sales_count }}</span></span>
                                  </template>
                                  <div class="table-responsive">
                                    <table class="table table-striped m-b-0">
                                      <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>DESIGNATION</th>
                                        <th>ESPECE</th>
                                        <th>A TERME</th>
                                        <th>CHIF D'AFF</th>
                                        <th>RECOUVR.</th>
                                        <th>RECETTE</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr v-for="(item, index) in queryApiData.table_view_client_group_sales"
                                          :key="index">
                                        <td><b>{{ index + 1 }}</b></td>
                                        <td><b>{{ item.name }}</b></td>
                                        <td><b>{{ item.ventes_espece|formatMoney }}</b></td>
                                        <td><b>{{ item.ventes_a_terme|formatMoney }}</b></td>
                                        <td><b>{{ item.total_ventes|formatMoney }}</b></td>
                                        <td><b>{{ item.recouvrement|formatMoney }}</b></td>
                                        <td><b>{{ item.recette|formatMoney }}</b></td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </b-tab>
                              </b-tabs>
                              <h3 class="m-t-10"><i class="fa fa-arrow-circle-right text-orange"></i> Documents de vente
                              </h3>
                              <b-tabs nav-class="nav-tabs-inverse" nav-wrapper-class="nav nav-tabs">
                                <b-tab>
                                  <template slot="title">
                                    <span class="d-sm-none">Factures espèce</span>
                                    <span class="d-sm-block d-none">Factures espèce <span
                                        class="badge badge-warning">{{ queryApiData.table_view_cash_sales_documents_count }}</span></span>
                                  </template>
                                  <div class="table-responsive">
                                    <table class="table table-striped m-b-0">
                                      <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>REFERENCE</th>
                                        <th>TYPE</th>
                                        <th>DATE</th>
                                        <th>CODE CLIENT</th>
                                        <th>DESIGNATION</th>
                                        <th>MONTANT</th>
                                        <th>PAIEMENT</th>
                                        <th>COMMERCIAL</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr v-for="(item, index) in queryApiData.table_view_cash_sales_documents"
                                          :key="index">
                                        <td><b>{{ index + 1 }}</b></td>
                                        <td><b><a href="javascript:;"
                                                  @click="showSalesOrderItem(item.id)">{{ item.server_side_code != null && item.server_side_code != '' ? item.server_side_code : '-----------------' }}</a></b>
                                        </td>
                                        <td><span class="label"
                                                  :class="{'label-danger':item.type === 'RETURN','label-success':item.type === 'SALE'}">{{ item.type_name }}</span>
                                        </td>
                                        <td>{{ item.formatted_creation_date }}</td>
                                        <td><b><a href="javascript:;"
                                                  @click="showClientItem(item.client_id)">{{ item.client_erp_code }}</a></b>
                                        </td>
                                        <td><b>{{ item.client_name }}</b></td>
                                        <td><b>{{ item.order_amount_ttc|formatMoney }}</b></td>
                                        <td>{{ item.payment_method_name }}</td>
                                        <td>{{ item.seller_name }}</td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </b-tab>
                                <b-tab>
                                  <template slot="title">
                                    <span class="d-sm-none">Factures à terme</span>
                                    <span class="d-sm-block d-none">Factures à terme <span
                                        class="badge badge-warning">{{ queryApiData.table_view_credit_sales_documents_count }}</span></span>
                                  </template>
                                  <div class="table-responsive">
                                    <table class="table table-striped m-b-0">
                                      <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>REFERENCE</th>
                                        <th>TYPE</th>
                                        <th>DATE</th>
                                        <th>CODE CLIENT</th>
                                        <th>DESIGNATION</th>
                                        <th>MONTANT</th>
                                        <th>PAIEMENT</th>
                                        <th>COMMERCIAL</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr v-for="(item, index) in queryApiData.table_view_credit_sales_documents"
                                          :key="index">
                                        <td><b>{{ index + 1 }}</b></td>
                                        <td><b><a href="javascript:;"
                                                  @click="showSalesOrderItem(item.id)">{{ item.server_side_code != null && item.server_side_code != '' ? item.server_side_code : '-----------------' }}</a></b>
                                        </td>
                                        <td><span class="label"
                                                  :class="{'label-danger':item.type === 'RETURN','label-success':item.type === 'SALE'}">{{ item.type_name }}</span>
                                        </td>
                                        <td>{{ item.formatted_creation_date }}</td>
                                        <td><b><a href="javascript:;"
                                                  @click="showClientItem(item.client_id)">{{ item.client_erp_code }}</a></b>
                                        </td>
                                        <td><b>{{ item.client_name }}</b></td>
                                        <td><b>{{ item.order_amount_ttc|formatMoney }}</b></td>
                                        <td>{{ item.payment_method_name }}</td>
                                        <td>{{ item.seller_name }}</td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </b-tab>
                                <b-tab active>
                                  <template slot="title">
                                    <span class="d-sm-none">Factures Annulées</span>
                                    <span class="d-sm-block d-none">Factures annulées <span
                                        class="badge badge-warning">{{ queryApiData.table_view_sales_return_documents_count }}</span></span>
                                  </template>
                                  <div class="table-responsive">
                                    <table class="table table-striped m-b-0">
                                      <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>REFERENCE</th>
                                        <th>TYPE</th>
                                        <th>DATE</th>
                                        <th>CODE CLIENT</th>
                                        <th>DESIGNATION</th>
                                        <th>MONTANT</th>
                                        <th>PAIEMENT</th>
                                        <th>COMMERCIAL</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr v-for="(item, index) in queryApiData.table_view_sales_return_documents"
                                          :key="index">
                                        <td><b>{{ index + 1 }}</b></td>
                                        <td><b><a href="javascript:;"
                                                  @click="showSalesOrderItem(item.id)">{{ item.server_side_code != null && item.server_side_code != '' ? item.server_side_code : '-----------------' }}</a></b>
                                        </td>
                                        <td><span class="label"
                                                  :class="{'label-danger':item.type === 'RETURN','label-success':item.type === 'SALE'}">{{ item.type_name }}</span>
                                        </td>
                                        <td>{{ item.formatted_creation_date }}</td>
                                        <td><b><a href="javascript:;"
                                                  @click="showClientItem(item.client_id)">{{ item.client_erp_code }}</a></b>
                                        </td>
                                        <td><b>{{ item.client_name }}</b></td>
                                        <td><b>{{ item.order_amount_ttc|formatMoney }}</b></td>
                                        <td>{{ item.payment_method_name }}</td>
                                        <td>{{ item.seller_name }}</td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </b-tab>
                              </b-tabs>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <b-card no-body>
                          <b-card-header header-tag="header" class="card-header text-black pointer-cursor"
                                         v-b-toggle.accordion2>
                            <h2><i class="fa fa-arrow-circle-down text-orange"></i> Détails Règlements</h2>
                          </b-card-header>
                          <b-collapse id="accordion2" accordion="my-accordion">
                            <b-card-body>
                              <div>
                                                        <span class="pull-right">
                                                            <a v-if="printBreApiData == null"
                                                               class="btn btn-warning m-b-10 mr-2"
                                                               @click="onDocumentDownloadClick"><i
                                                                v-if="!printBreApiDataIsLoading"
                                                                class="fa fa-download fa-fw fa-lg"></i><i
                                                                class="fa fa-spinner fa-fw fa-lg fa-spin"
                                                                v-if="printBreApiDataIsLoading"></i> Télécharger le Détails des Règlements</a>
                                                            <a v-if="printBreApiData != null &&  !printBreApiDataIsLoading &&  printBreApiData.pdf_url != null"
                                                               :href="printBreApiData.pdf_url" target="_blank"
                                                               class="btn btn-success m-b-10 mr-2"><i
                                                                class="fa fa-print fa-fw fa-lg"></i> Imprimer le document</a>
                                                        </span>
                              </div>
                              <h3 class="m-t-10"><i class="fa fa-arrow-circle-right text-orange"></i> Versement Accomptes
                              </h3>
                              <div class="table-responsive">
                                <table class="table table-striped m-b-0">
                                  <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>DATE</th>
                                    <th>CODE CLIENT</th>
                                    <th>DESIGNATION</th>
                                    <th>TELEPHONE</th>
                                    <th>AGENT RECOUVREUR</th>
                                    <th class="text-right">MONTANT</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr v-for="(item, index) in queryApiData.table_view_placement_client_topup"
                                      :key="index">
                                    <td><b>{{ index + 1 }}</b></td>
                                    <td><b>{{ item.date }}</b></td>
                                    <td><b>{{ item.erp_code }}</b></td>
                                    <td><b>{{ item.name }}</b></td>
                                    <td>{{ item.phone }}</td>
                                    <td>{{ item.cashier_name }}</td>
                                    <td class="text-right"><b>{{ item.total_payment|formatMoney }}</b></td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                              <br/>

                              <h3 class="m-t-10"><i class="fa fa-arrow-circle-right text-orange"></i> Paiement Ristournes
                              </h3>
                              <div class="table-responsive">
                                <table class="table table-striped m-b-0">
                                  <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>DATE</th>
                                    <th>CODE CLIENT</th>
                                    <th>DESIGNATION</th>
                                    <th>TELEPHONE</th>
                                    <th>AGENT PAYEUR.</th>
                                    <th class="text-right">MONTANT</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr v-for="(item, index) in queryApiData.table_view_bonus_client_topup"
                                      :key="index">
                                    <td><b>{{ index + 1 }}</b></td>
                                    <td><b>{{ item.date }}</b></td>
                                    <td><b>{{ item.erp_code }}</b></td>
                                    <td><b>{{ item.name }}</b></td>
                                    <td>{{ item.phone }}</td>
                                    <td>{{ item.cashier_name }}</td>
                                    <td class="text-right"><b>{{ item.total_payment|formatMoney }}</b></td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                              <br/>

                              <h3 class="m-t-10"><i class="fa fa-arrow-circle-right text-orange"></i> Règlements
                                Espèce/Chèque/Virement</h3>
                              <b-tabs nav-class="nav-tabs-inverse" nav-wrapper-class="nav nav-tabs">
                                <b-tab active>
                                  <template slot="title">
                                    <span class="d-sm-none">Codes Clients</span>
                                    <span class="d-sm-block d-none">Règlements par Code Client <span
                                        class="badge badge-warning">{{ queryApiData.table_view_client_code_payments_count }}</span></span>
                                  </template>
                                  <div class="table-responsive">
                                    <table class="table table-striped m-b-0">
                                      <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>CODE CLIENT</th>
                                        <th>DESIGNATION</th>
                                        <th class="text-center">ESPECE</th>
                                        <th class="text-center">CHEQUE</th>
                                        <th class="text-center">VIREMENT</th>
                                        <th class="text-right">TOTAL REGLEMENT</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr v-for="(item, index) in queryApiData.table_view_client_code_payments"
                                          :key="index">
                                        <td><b>{{ index + 1 }}</b></td>
                                        <td><b>{{ item.erp_code }}</b></td>
                                        <td><b>{{ item.name }}</b></td>
                                        <td class="text-center"><b>{{ item.total_cash_payment|formatMoney }}</b></td>
                                        <td class="text-center"><b>{{ item.total_bank_cheque_payment|formatMoney }}</b></td>
                                        <td class="text-center"><b>{{ item.total_bank_transfer_payment|formatMoney }}</b>
                                        </td>
                                        <td class="text-right"><b>{{ item.total_payment|formatMoney }}</b></td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </b-tab>
                                <b-tab>
                                  <template slot="title">
                                    <span class="d-sm-none">Type Clients</span>
                                    <span class="d-sm-block d-none">Règlements par type de client <span
                                        class="badge badge-warning">{{ queryApiData.table_view_client_group_payments_count }}</span></span>
                                  </template>
                                  <div class="table-responsive">
                                    <table class="table table-striped m-b-0">
                                      <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>DESIGNATION</th>
                                        <th class="text-center">ESPECE</th>
                                        <th class="text-center">CHEQUE</th>
                                        <th class="text-center">VIREMENT</th>
                                        <th class="text-right">TOTAL REGLEMENT</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr v-for="(item, index) in queryApiData.table_view_client_group_payments"
                                          :key="index">
                                        <td><b>{{ index + 1 }}</b></td>
                                        <td><b>{{ item.name }}</b></td>
                                        <td class="text-center"><b>{{ item.total_cash_payment|formatMoney }}</b></td>
                                        <td class="text-center"><b>{{ item.total_bank_cheque_payment|formatMoney }}</b></td>
                                        <td class="text-center"><b>{{ item.total_bank_transfer_payment|formatMoney }}</b>
                                        </td>
                                        <td class="text-right"><b>{{ item.total_payment|formatMoney }}</b></td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </b-tab>
                                <b-tab>
                                  <template slot="title">
                                    <span class="d-sm-none">Clients</span>
                                    <span class="d-sm-block d-none">Règlements par Client <span class="badge badge-warning">{{ queryApiData.table_view_client_payments_count }}</span></span>
                                  </template>
                                  <div class="table-responsive">
                                    <table class="table table-striped m-b-0">
                                      <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>REFERENCE</th>
                                        <th>TYPE</th>
                                        <th>LOCALITE</th>
                                        <th>DESIGNATION</th>
                                        <th>TELEPHONE</th>
                                        <th class="text-center">ESPECE</th>
                                        <th class="text-center">CHEQUE</th>
                                        <th class="text-center">VIREMENT</th>
                                        <th class="text-right">TOTAL REGLEMENT</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr v-for="(item, index) in queryApiData.table_view_client_payments"
                                          :key="index">
                                        <td><b>{{ index + 1 }}</b></td>
                                        <td><b><a href="javascript:;"
                                                  @click="showClientItem(item.id)">{{ item.erp_code != null && item.erp_code != '' ? item.erp_code : '-----------------' }}</a></b>
                                        </td>
                                        <td>{{ item.group_name }}</td>
                                        <td>{{ item.location_name }}</td>
                                        <td><b>{{ item.name }}</b></td>
                                        <td>{{ item.phone }}</td>
                                        <td class="text-center"><b>{{ item.total_cash_payment|formatMoney }}</b></td>
                                        <td class="text-center"><b>{{ item.total_bank_cheque_payment|formatMoney }}</b></td>
                                        <td class="text-center"><b>{{ item.total_bank_transfer_payment|formatMoney }}</b>
                                        </td>
                                        <td class="text-right"><b>{{ item.total_payment|formatMoney }}</b></td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </b-tab>
                                <b-tab>
                                  <template slot="title">
                                    <span class="d-sm-none">Agents</span>
                                    <span class="d-sm-block d-none">Règlements par agent <span class="badge badge-warning">{{ queryApiData.table_view_payments_to_employee_count }}</span></span>
                                  </template>
                                  <div class="table-responsive">
                                    <table class="table table-striped m-b-0">
                                      <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>LOCALITE</th>
                                        <th>DESIGNATION</th>
                                        <th>TELEPHONE</th>
                                        <th class="text-center">ESPECE</th>
                                        <th class="text-center">CHEQUE</th>
                                        <th class="text-center">VIREMENT</th>
                                        <th class="text-right">TOTAL REGLEMENT</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr v-for="(item, index) in queryApiData.table_view_payments_to_employee"
                                          :key="index">
                                        <td><b>{{ index + 1 }}</b></td>
                                        <td>{{ item.location_name }}</td>
                                        <td><b>{{ item.name }}</b></td>
                                        <td>{{ item.phone }}</td>
                                        <td class="text-center"><b>{{ item.total_cash_payment|formatMoney }}</b></td>
                                        <td class="text-center"><b>{{ item.total_bank_cheque_payment|formatMoney }}</b></td>
                                        <td class="text-center"><b>{{ item.total_bank_transfer_payment|formatMoney }}</b>
                                        </td>
                                        <td class="text-right"><b>{{ item.total_payment|formatMoney }}</b></td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </b-tab>
                              </b-tabs>

                              <h3 class="m-t-10"><i class="fa fa-arrow-circle-right text-orange"></i> Règlements par
                                Accompte/Ristourne</h3>
                              <div class="table-responsive">
                                <table class="table table-striped m-b-0">
                                  <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>LOCALITE</th>
                                    <th>DESIGNATION</th>
                                    <th>TELEPHONE</th>
                                    <th>AGENT</th>
                                    <th class="text-center">DEPOT</th>
                                    <th class="text-center">RISTOURNE</th>
                                    <th class="text-right">TOTAL REGLEMENT</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr v-for="(item, index) in queryApiData.table_view_other_client_payments_to_employee"
                                      :key="index">
                                    <td><b>{{ index + 1 }}</b></td>
                                    <td>{{ item.location_name }}</td>
                                    <td><b>{{ item.name }}</b></td>
                                    <td>{{ item.phone }}</td>
                                    <td>{{ item.cashier_name }}</td>
                                    <td class="text-center"><b>{{ item.total_placement_payment|formatMoney }}</b></td>
                                    <td class="text-center"><b>{{ item.total_bonus_payment|formatMoney }}</b></td>
                                    <td class="text-right"><b>{{ item.total_payment|formatMoney }}</b></td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>

                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>

                    </div>
                  </div>

                  <div class="invoice-footer">
                    <p class="text-center m-b-5 f-w-600">
                      MERCI DE VOTRE FIDELITE
                    </p>
                    <p class="text-center">
                      <span class="m-r-10"><i class="fa fa-fw fa-lg fa-globe"></i> {{ queryApiData.company_website }}</span>
                      <span class="m-r-10"><i class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{ queryApiData.company_phone }}</span>
                      <span class="m-r-10"><i
                          class="fa fa-fw fa-lg fa-envelope"></i> {{ queryApiData.company_email }}</span>
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="col-12" v-else>
            <div class="panel">
              <div class="panel-body">
                <div class="invoice">
                  <div class="invoice-company">

                    <h3 class="page-header">Suivi des vente &amp; encaissement.<br/>
                      <small class="lead">Dans cette section, vous pouvez suivre les ventes et encaissements d'un
                        emplacement donné sur la période selectionnée.
                      </small></h3>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <!-- Dialogs -->
        <b-modal
            centered
            hide-header
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            id="formModal"
            title="Modal Title"
            size="md"
            @show="()=>{
              this.queryApiDataErrorMessage = null;
            }"
            @hidden="()=>{}">

          <div class="row ">
            <p class="col-12 text-right">
              <a href="javascript:;" class="btn btn-danger" @click="$bvModal.hide('formModal')"><i
                  class="fa fa-times fa-fw fa-lg"></i></a>
            </p>
          </div>

          <h1 class="page-header">Choisir un point de vente <small class="lead"></small></h1>
          <div class="row" v-if="queryApiDataIsLoading">
            <div class="col-12 m-b-10">
              <small class="lead text-warning"><i class="fas fa-spinner fa-pulse"></i> Chargement en cours...</small><br/>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12" v-if="queryApiDataErrorMessage != null">
              <div class="alert alert-danger">
                {{ queryApiDataErrorMessage }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <form
                  @submit.prevent="queryData">
                <div class="form-group row">
                  <div class="col-12">
                    <label>Choisir un point de vente</label>
                    <v-select label="name" :options="pageIndexApiData.warehouses"
                              :reduce="item => item.id"
                              v-model="form.warehouse_id"/>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-12">
                    <label>Définir la période</label>
                    <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                       opens="right"
                                       :ranges="dateRanges"
                                       :locale-data="{
                                                      format: 'dd-mm-yyyy',
                                                      separator: ' - ',
                                                      applyLabel: 'Appliquer',
                                                      cancelLabel: 'Annuler',
                                                      weekLabel: 'S',
                                                      customRangeLabel: 'Plage Perso.',
                                                      daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                      monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                      firstDay: 1
                                                    }"
                                       :singleDatePicker="false"
                                       :timePicker="false"
                                       :timePicker24Hour="true"
                                       :showWeekNumbers="false"
                                       :showDropdowns="false"
                                       :autoApply="true"
                                       v-model="form.date_range"
                                       @update="()=>{}"
                                       :linkedCalendars="false">
                      <template v-slot:input="picker" class="text-right">
                        {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                        <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                      </template>
                    </date-range-picker>
                  </div>
                </div>
                <!--
                <div class="form-group row">
                  <div class="col-12">
                    <div class="checkbox checkbox-css checkbox-inline">
                      <input type="checkbox" id="pdf" v-model="form.to_pdf"/>
                      <label for="pdf"><i class="fas fa-download text-warning"></i>
                        Télécharger au format PDF</label>
                    </div>
                  </div>
                </div>
                -->
                <div class="form-group row">
                  <div class="col-12 text-right">
                    <button :disabled="queryApiDataIsLoading" type="submit" class="btn btn-dark">
                      <i class="fas fa-spinner fa-spin" v-if="queryApiDataIsLoading"></i>
                      <i class="fas fa-filter" v-else></i> Filtrer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <!--
          <div class="row ">
            <p class="col-12 text-right">
              <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-comment fa-fw fa-lg"></i></a>
              <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-cogs fa-fw fa-lg"></i></a>
            </p>
          </div>
          -->
        </b-modal>

        <b-modal
            centered
            hide-header
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            id="clientModal"
            title="Modal Title"
            size="xl"
            @show="()=>{}"
            @hidden="()=>{}">

          <div class="row ">
            <p class="col-12 text-right">
              <a href="javascript:;" class="btn btn-danger" @click="$bvModal.hide('clientModal')"><i
                  class="fa fa-times fa-fw fa-lg"></i></a>
            </p>
          </div>

          <client-account-component :itemId="selectedClientId"/>

          <!--
          <div class="row ">
            <p class="col-12 text-right">
              <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-comment fa-fw fa-lg"></i></a>
              <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-cogs fa-fw fa-lg"></i></a>
            </p>
          </div>
          -->
        </b-modal>
        <b-modal
            centered
            hide-header
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            id="salesOrderModal"
            title="Modal Title"
            size="xl"
            @show="()=>{}"
            @hidden="()=>{}">

          <div class="row ">
            <p class="col-12 text-right">
              <a href="javascript:;" class="btn btn-danger" @click="$bvModal.hide('salesOrderModal')"><i
                  class="fa fa-times fa-fw fa-lg"></i></a>
            </p>
          </div>

          <sales-order-component :itemId="selectedSalesOrderId"/>

          <!--
          <div class="row ">
            <p class="col-12 text-right">
              <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-comment fa-fw fa-lg"></i></a>
              <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-cogs fa-fw fa-lg"></i></a>
            </p>
          </div>
          -->
        </b-modal>
      </div>
    </div>
    <div class="panel-loader" v-else><span class="spinner-small"></span></div>
  </div>
</template>


<script>
import pageApi from '../../store/api/monitoring/warehouse-periodic-sales-report-dashboard-page-api'
import LocalStorage from "../../store/local-storage";
import CompanyLicenceComponent from '../../components/app/base/CompanyLicenceComponent.vue'
import ClientAccountComponent from "@/components/app/ClientAccountComponent";
import SalesOrderComponent from "@/components/app/SalesOrderComponent";

export default {
  name: 'warehouse-periodic-sales-report-dashboard-page-component',
  components: {
    SalesOrderComponent,
    ClientAccountComponent,
    CompanyLicenceComponent,
  },
  props: {},
  watch: {},
  computed: {},
  methods: {
    //Initialization Methods
    loadPageScripts() {
    },

    formatDateInput (value) {
      if (value) {
        return window.moment(String(value)).format(window.appDefaultDisplayDateTimeFormat)
      }
      return null;
    },

    //
    showClientItem(id) {
      //
      this.selectedClientId = id;
      this.$bvModal.show('clientModal');
    },

    showSalesOrderItem(id) {
      //
      this.selectedSalesOrderId = id;
      this.$bvModal.show('salesOrderModal');
    },


    onDocumentDownloadClick() {
      this.printBreData();
    },


    //Other Methods
    async getPageIndexApiData() {
      this.pageIndexApiDataIsLoading = true;
      this.pageIndexApiDataErrorMessage = null;
      try {
        const response = await pageApi.getIndexData();
        //console.log(response);
        this.pageIndexApiData = response.data;
        this.pageIndexApiDataIsLoading = false;
        this.pageIndexApiDataErrorMessage = null;
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          if (error.response.status === 401 || error.response.status === 403 || error.response.status === 419) {
            this.pageIndexApiDataIsLoading = false;
            this.pageIndexApiDataErrorMessage = error.response.data.message;
            LocalStorage.clearSessionData();
            this.$router.push('login');
          } else {
            this.pageIndexApiDataIsLoading = false;
            this.pageIndexApiDataErrorMessage = error.response.data.message;
            //
            this.$swal({
              icon: 'error',//'info','success','warning','error','question',
              toast: true,
              position: 'bottom-end',
              timer: 3000,
              showConfirmButton: false,
              title: 'HTTP ' + error.response.status,
              text: error.response.data.message,
            })
          }
        } else {
          this.pageIndexApiDataIsLoading = false;
          this.pageIndexApiDataErrorMessage = error.message;
          //
          this.$swal({
            icon: 'error',//'info','success','warning','error','question',
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            showConfirmButton: false,
            //title: error.message
            text: error.message,
          })
        }
      }
    },

    async queryData() {
      this.printBreApiData = null;

      this.queryApiDataIsLoading = true;
      this.queryApiDataErrorMessage = null;
      this.queryApiDataValidationErrors = [];
      try {
        const response = await pageApi.queryData({
          warehouse_id: this.form.warehouse_id,
          date_from: this.formatDateInput(this.form.date_range.startDate),
          date_to: this.formatDateInput(this.form.date_range.endDate),
          to_pdf: this.form.to_pdf
        });
        //console.log(response);
        this.queryApiData = response.data;
        this.queryApiDataIsLoading = false;
        this.queryApiDataErrorMessage = null;
        this.queryApiDataValidationErrors = [];

        this.$bvModal.hide('formModal');

      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.queryApiDataIsLoading = false;
          this.queryApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.queryApiDataValidationErrors = error.response.data.errors;
          } else {
            this.queryApiDataValidationErrors = [];
          }
        } else {
          this.queryApiDataIsLoading = false;
          this.queryApiDataErrorMessage = error.message;
          this.queryApiDataValidationErrors = [];
        }
      }
    },

    async printBreData() {
      this.printBreApiDataIsLoading = true;
      this.printBreApiDataErrorMessage = null;
      try {
        const response = await pageApi.printBreData({
          warehouse_id: this.form.warehouse_id,
          date_from: this.formatDateInput(this.form.date_range.startDate),
          date_to: this.formatDateInput(this.form.date_range.endDate),
        });
        //console.log(response);
        this.printBreApiData = response.data;
        this.printBreApiDataIsLoading = false;
        this.printBreApiDataErrorMessage = null;
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.printBreApiDataIsLoading = false;
          this.printBreApiDataErrorMessage = error.response.data.message;
          //
          this.$swal({
            icon: 'error',//'info','success','warning','error','question',
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            showConfirmButton: false,
            title: 'HTTP ' + error.response.status,
            text: error.response.data.message,
          })
        } else {
          this.printBreApiDataIsLoading = false;
          this.printBreApiDataErrorMessage = error.message;
          //
          this.$swal({
            icon: 'error',//'info','success','warning','error','question',
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            showConfirmButton: false,
            //title: error.message
            text: error.message,
          })
        }
      }
    },
  },
  data: function () {

    let today = new Date()
    today.setHours(0, 0, 0, 0)
    let todayEnd = new Date()
    todayEnd.setHours(11, 59, 59, 999);
    let yesterdayStart = new Date()
    yesterdayStart.setDate(today.getDate() - 1)
    yesterdayStart.setHours(0, 0, 0, 0);
    let yesterdayEnd = new Date()
    yesterdayEnd.setDate(today.getDate() - 1)
    yesterdayEnd.setHours(11, 59, 59, 999);
    let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999);


    return {
      appName: window.appConfig.appName,
      appCurrency: window.appConfig.appCurrency,
      intervalId01: 0,

      selectedClientId: null,
      selectedSalesOrderId: null,

      form: {
        warehouse_id: null,
        date_range: {
          startDate: null,
          endDate: null,
        },
        to_pdf: false,
      },

      dateRanges: {
        "Aujourd'hui": [today, todayEnd],
        "Hier": [yesterdayStart, yesterdayEnd],
        "Mois en cours": [thisMonthStart, thisMonthEnd],
        "Mois dernier": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999)
        ],
        "Année en cours": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999)
        ],
        "Année dernière": [
          new Date(today.getFullYear() - 1, 0, 1),
          new Date(today.getFullYear()- 1, 11, 31, 11, 59, 59, 999)
        ],
      },

      //API Data
      //
      pageIndexApiData: null,
      pageIndexApiDataIsLoading: false,
      pageIndexApiDataErrorMessage: null,

      queryApiData: null,
      queryApiDataIsLoading: false,
      queryApiDataErrorMessage: null,
      queryApiDataValidationErrors: [],

      printBreApiData: null,
      printBreApiDataIsLoading: false,
      printBreApiDataErrorMessage: null,
    };
  },
  created: function () {
    this.getPageIndexApiData();
  },
  mounted: function () {
    this.loadPageScripts();
  },
  beforeDestroy: function () {
  },
}
</script>
