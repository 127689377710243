/**
 * Here are all the Backend api calls for our modules
 */
import appApiClient from '../app-api-client'

const pageUriPrefix = "/monitoring/sales/periodic-report/per-warehouse";

export default {

    getIndexData() {
        return appApiClient().get(pageUriPrefix+'/index');
    },

    queryData(data) {
        return appApiClient().post(pageUriPrefix + '/query', data);
    },

    printBreData(data) {
        return appApiClient().post(pageUriPrefix + '/print-bre', data);
    },

}